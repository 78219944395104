var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "todoapp"
  }, [_c("header", {
    staticClass: "header"
  }, [_c("input", {
    staticClass: "new-todo",
    attrs: {
      autocomplete: "off",
      placeholder: "Todo List"
    },
    on: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addTodo.apply(null, arguments);
      }
    }
  })]), _vm._v(" "), _c("section", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.todos.length,
      expression: "todos.length"
    }],
    staticClass: "main"
  }, [_c("input", {
    staticClass: "toggle-all",
    attrs: {
      id: "toggle-all",
      type: "checkbox"
    },
    domProps: {
      checked: _vm.allChecked
    },
    on: {
      change: function change($event) {
        return _vm.toggleAll({
          done: !_vm.allChecked
        });
      }
    }
  }), _vm._v(" "), _c("label", {
    attrs: {
      for: "toggle-all"
    }
  }), _vm._v(" "), _c("ul", {
    staticClass: "todo-list"
  }, _vm._l(_vm.filteredTodos, function (todo, index) {
    return _c("todo", {
      key: index,
      attrs: {
        todo: todo
      },
      on: {
        toggleTodo: _vm.toggleTodo,
        editTodo: _vm.editTodo,
        deleteTodo: _vm.deleteTodo
      }
    });
  }), 1)]), _vm._v(" "), _c("footer", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.todos.length,
      expression: "todos.length"
    }],
    staticClass: "footer"
  }, [_c("span", {
    staticClass: "todo-count"
  }, [_c("strong", [_vm._v(_vm._s(_vm.remaining))]), _vm._v("\n    " + _vm._s(_vm._f("pluralize")(_vm.remaining, "item")) + " left\n  ")]), _vm._v(" "), _c("ul", {
    staticClass: "filters"
  }, _vm._l(_vm.filters, function (val, key) {
    return _c("li", {
      key: key
    }, [_c("a", {
      class: {
        selected: _vm.visibility === key
      },
      on: {
        click: function click($event) {
          $event.preventDefault();
          _vm.visibility = key;
        }
      }
    }, [_vm._v(_vm._s(_vm._f("capitalize")(key)))])]);
  }), 0)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };